body {
  font-family: "Oswald", sans-serif;
}
nav {
  background-color: rgb(62, 86, 65);
}

.navbar-toggler {
  border: none !important;
}

div .navbar {
  padding: 0 !important;
}
.navbar-brand,
.navbar a {
  color: white;
  padding: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 20px;
  margin-bottom: 10px;
}

#dog-icon {
  max-width: 30px;
}

.moose-card {
  margin-top: 5% !important;
}
#moose-pic {
  max-width: 75% !important;
}

#portfolio-pic {
  max-width: 75%;
}

.summary {
  text-align: justify;
  margin-bottom: 10%;
  width: 80%;
}

ul {
  list-style: none !important;
}

.project-container {
  padding-bottom: 10%;
}

.contact-form {
  margin-bottom: 20% !important;
}

.skills-list {
  margin-bottom: 2% !important;
}
.resume-download {
  margin-bottom: 15% !important;
}

@media screen and (min-width: 576px) {
  .summary {
    width: 60%;
  }
  #portfolio-pic {
    max-width: 50%;
  }
  #moose-pic {
    max-width: 50% !important;
  }
}

@media screen and (min-width: 768px) {
  .summary {
    width: 40%;
  }
  #portfolio-pic {
    max-width: 30%;
  }
  #moose-pic {
    max-width: 30% !important;
  }
}

@media screen and (min-width: 1400px) {
  #portfolio-pic {
    max-width: 15%;
  }
  #moose-pic {
    max-width: 15% !important;
  }
}
